<template>
  <v-dialog v-model="pixDialog" persistent max-width="500">
    <v-card>
      <v-card-title>Profile picture</v-card-title>
      <v-card-text>
        <client-only>
          <image-uploader
            :scaleRatio="0.5"
            :quality="1.0"
            :debug="1"
            accept=".gif, .jpg, .png"
            v-model="myPicture"
            @input="setImage"
            @onUpload="startImageResize"
            @onComplete="endImageResize"
          ></image-uploader>
        </client-only>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../../mixins/utils";
export default {
  mixins: [Utils],
  props: {
    pixDialog: Boolean,
    myId: { type: Number, default: 0 },
  },
  data() {
    return {
      myPicture: null,
      displayPix: require("../../../assets/images/no_person.jpg"),
    };
  },
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closePix", payload);
    },
    submit() {
      let picture = this.myPicture;
      let data = { picture };
      // console.log(this.myPicture);
      let payload = { id: this.myId, data: data, state: false };
      this.$emit("submitPix", payload);
    },
  },
};
</script>
