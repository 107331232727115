<template>
  <v-dialog v-model="profileDialog" persistent max-width="700">
    <v-card>
      <v-card-title>My Profile Update</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="first name"
              v-model="create.first_name"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="6"
            ><v-text-field
              dense
              label="last name"
              v-model="create.last_name"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="salutation"
              :items="salutlist"
              item-value="id"
              item-text="name"
              v-model="create.salutationsid"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="gender"
              :items="genderlist"
              item-value="id"
              item-text="name"
              v-model="create.gendersid"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
              dense
              label="married status"
              :items="maritalist"
              item-value="id"
              item-text="name"
              v-model="create.married_statusid"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="create.date_of_birth"
                  label="Picker without buttons"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="create.date_of_birth"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-text-field
          dense
          maxLength="10"
          counter
          label="mobile Phone"
          v-model="create.mobile_phone"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              :disabled="$v.create.$invalid"
              @click="submit"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  decimal,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    profileDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      datalist: [],
      genderlist: [],
      salutlist: [],
      maritalist: [],
      menu2: false,
      create: {
        first_name: null,
        last_name: null,
        salutationsid: null,
        gendersid: null,
        married_statusid: null,
        date_of_birth: null,
        mobile_phone: null,
      },
    };
  },
  validations: {
    create: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      mobile_phone: {
        required,
        decimal,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  created() {
    this.myGender();
    this.salute();
    this.marital();
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .find()
        .then((response) => {
          console.log(response.data);
          this.create.first_name = response.data.first_name;
          this.create.last_name = response.data.last_name;
          this.create.salutationsid = response.data.salutationsId;
          this.create.gendersid = response.data.gendersid;
          this.create.married_statusid = response.data.marital_status_id;
          this.create.date_of_birth = response.data.date_of_birth;
          this.create.mobile_phone = response.data.mobile_phone;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    myGender() {
      let self = this;
      Restful.general.genders
        .list()
        .then((response) => {
          console.log(response.data);
          this.genderlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    salute() {
      let self = this;
      Restful.general.salutations
        .list()
        .then((response) => {
          console.log(response.data);
          this.salutlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    marital() {
      let self = this;
      Restful.general.marital
        .list()
        .then((response) => {
          console.log(response.data);
          this.maritalist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeProfile", payload);
    },
    submit() {
      let salutationsid = this.create.salutationsid;
      let gendersid = this.create.gendersid;
      let married_statusid = this.create.married_statusid;
      let first_name = this.create.first_name;
      let midName = this.create.midName;
      let last_name = this.create.last_name;
      let date_of_birth = Date.parse(this.create.date_of_birth);
      let mobile_phone = this.create.mobile_phone;
      let data = {
        salutationsid,
        gendersid,
        married_statusid,
        first_name,
        midName,
        last_name,
        date_of_birth,
        mobile_phone,
      };
      console.log(data);

      let payload = { state: false, data: data };
      this.$emit("submitProfile", payload);
    },
  },
};
</script>
