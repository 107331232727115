<template>
  <div>
    <v-app>
      <v-navigation-drawer v-model="drawer" app color="white" class="navibar">
        <div class="app_name" style="position: fixed">
          Umohe
          <span style="color: green; padding-left: 5px"> Healthcare </span>
        </div>
        <!-- <div style="position: fixed">
          admin portal
        </div> -->
        <div class="portal_name">Admin Portal</div>
        <v-divider></v-divider>

        <div class="profilia">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                x-large
                class="myimg"
                v-on="on"
                @click.stop="pixDialog = true"
              >
                <v-avatar size="64">
                  <img :src="avatar()" :alt="userName" />
                </v-avatar>
              </v-btn>
            </template>
            <span>Update your avatar</span>
          </v-tooltip>
          <ProfilePix
            :pixDialog="pixDialog"
            @closePix="closePix"
            @submitPix="submitPix"
          />

          <div class="myself">Welcome back {{ userName | capitalize }}</div>
          <ul>
            <li>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    icon
                    fab
                    color="blue"
                    v-on="on"
                    @click.stop="passDialog = true"
                  >
                    <v-icon>mdi-key</v-icon>
                  </v-btn>
                </template>
                <span>Change Password</span>
              </v-tooltip>
              <PasswordChange
                :passDialog="passDialog"
                @closePass="closePass"
                @submitPass="submitPass"
              />
            </li>
            <li>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    icon
                    fab
                    color="blue"
                    v-on="on"
                    @click.stop="profileDialog = true"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit Profile</span>
              </v-tooltip>
              <ProfileEdit
                :profileDialog="profileDialog"
                @closeProfile="closeProfile"
                @submitProfile="submitProfile"
              />
            </li>
            <li>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    icon
                    fab
                    color="red"
                    v-on="on"
                    @click="logout"
                  >
                    <v-icon>mdi-power-standby</v-icon>
                  </v-btn>
                </template>
                <span>Logout</span>
              </v-tooltip>
            </li>
          </ul>
        </div>

        <div class="navside">
          <v-list dense flat v-for="n in navlist" :key="n.title">
            <v-list-item ripple :to="n.links" router>
              <v-list-item-icon>
                <v-icon>{{ n.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ n.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense flat>
            <v-list-group
              v-for="item in items"
              :key="item.title"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.links"
                router
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <!-- <v-list dense>
            <v-list-group :value="false" prepend-icon="mdi-cog-outline">
              <template v-slot:activator>
                <v-list-item-title>Settings</v-list-item-title>
              </template>

              <v-list-group :value="true" sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>General</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="i in general"
                  :key="i.title"
                  :to="i.links"
                  router
                >
                  <v-list-item-title v-text="i.title"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="i.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>

              <v-list-group no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Subscribers</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="i in subscribers"
                  :key="i.title"
                  :to="i.links"
                  router
                >
                  <v-list-item-title v-text="i.title"></v-list-item-title>
                  <v-list-item-icon>
                    <v-icon v-text="i.icon"></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </v-list> -->
        </div>
      </v-navigation-drawer>

      <v-app-bar
        app
        elevate-on-scroll
        scroll-target="#route_content"
        color="rgb(252, 252, 255)"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="font-weight: 400; color: #999999">
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-2"
                v-on="on"
                color="rgb(188, 188, 188)"
                depressed
              >
                <v-icon style="font-weight: lighter; ">
                  mdi-comment-multiple
                </v-icon>
                <v-badge bottom bordered color="success" content="12" overlap>
                </v-badge>
              </v-btn>
            </template>
            <span>Today's Reviews</span>
          </v-tooltip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-2"
                v-on="on"
                color="rgb(188, 188, 188)"
                depressed
              >
                <v-icon style="font-weight: lighter; ">
                  mdi-account-multiple-outline
                </v-icon>
                <v-badge bottom bordered color="success" content="12" overlap>
                </v-badge>
              </v-btn>
            </template>
            <span>New Subscriptions</span>
          </v-tooltip> -->

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-2"
                color="rgb(188, 188, 188)"
                v-on="on"
                depressed
              >
                <v-icon>mdi-email-outline</v-icon>
                <v-badge bottom bordered color="info" content="1" overlap>
                </v-badge>
              </v-btn>
            </template>
            <span>Support Emails</span>
          </v-tooltip> -->

          <!-- <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                <v-avatar style="width: 35px; height: 35px;">
                  <img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="John"
                  />
                </v-avatar>
              </v-btn>
            </template>
            <v-list>
              <v-list-item key="profile" :to="{ name: 'user.profile' }" link>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item
                key="change_pass"
                @click.stop="change_pass_dialog = true"
                link
              >
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>
              <v-list-item key="logout" @click="logout" link>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-toolbar-items>
      </v-app-bar>

      <v-main class="main_content" id="route_content">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-main>
    </v-app>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../src/mixins/utils";
import PasswordChange from "./../components/accounts/myuser/change_password.vue";
import ProfileEdit from "./../components/accounts/myuser/profile.vue";
import ProfilePix from "../components/accounts/myuser/picture.vue";

export default {
  components: {
    PasswordChange,
    ProfileEdit,
    ProfilePix,
  },
  mixins: [Utils],
  data() {
    return {
      passDialog: false,
      pixDialog: false,
      profileDialog: false,
      userName: localStorage.getItem("user.name"),
      userPix: localStorage.getItem("user.pix"),
      drawer: null,
      subdrawer: null,
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      crumbs: [
        {
          text: "Dashboard",
          disabled: true,
          href: "dash",
        },
        {
          text: "Medical",
          disabled: false,
          href: "medical",
        },
      ],
      navlist: [
        {
          title: "dashboard",
          links: { name: "admin.dash" },
          icon: "mdi-view-dashboard",
        },
        {
          title: "web pages",
          links: { name: "admin.cms" },
          icon: "mdi-apps",
        },
      ],
      items: [
        {
          action: "mdi-circle-medium",
          title: "Billing",
          items: [
            {
              title: "payments",
              links: { name: "admin.billing.payments" },
              icon: "mdi-doctor",
            },
          ],
        },
        {
          action: "mdi-circle-medium",
          title: "Subscribers",
          items: [
            {
              title: "license",
              links: { name: "admin.license" },
              icon: "mdi-doctor",
            },
            {
              title: "medics",
              links: { name: "admin.medics" },
              icon: "mdi-doctor",
            },
            {
              title: "Pharmacies",
              links: { name: "admin.pharmacy" },
              icon: "mdi-pill",
            },
            {
              title: "laboratories",
              links: { name: "admin.labs" },
              icon: "mdi-watering-can",
            },
            // {
            //   title: "deliveries",
            //   links: {name: "admin.delivery"},
            //   icon: "mdi-moped",
            // },
            {
              title: "Patients",
              links: { name: "admin.patients" },
              icon: "mdi-emoticon-sick-outline",
            },
          ],
        },
        {
          title: "User manager",
          action: "mdi-circle-medium",
          items: [
            {
              title: "Users",
              links: { name: "admin.users" },
              icon: "mdi-account-group-outline",
            },
            {
              title: "Access Control",
              links: { name: "admin.myaccess" },
              icon: "mdi-account-lock-outline",
            },
          ],
        },
        {
          title: "General Data",
          action: "mdi-circle-medium",
          items: [
            {
              title: "measurements",
              links: { name: "admin.setting.measurecat" },
            },
            {
              title: "document types",
              links: { name: "admin.setting.documentype" },
            },
            {
              title: "meeting types",
              links: { name: "admin.setting.meetingypes" },
            },
            {
              title: "subscriber types",
              links: { name: "admin.setting.subscribertype" },
            },
            { title: "roles", links: { name: "admin.setting.roles" } },
            { title: "Country", links: { name: "admin.setting.countries" } },
          ],
        },
        {
          title: "General Service",
          action: "mdi-circle-medium",
          items: [
            {
              title: "Specialities",
              links: { name: "admin.specialty" },
            },
            {
              title: "information types",
              links: { name: "admin.setting.infotype" },
            },
          ],
        },
        {
          title: "Medical Data",
          action: "mdi-circle-medium",
          items: [
            {
              title: "Medic Route",
              links: { name: "admin.medic.route" },
            },
            {
              title: "Medic Frequency",
              links: { name: "admin.medic.freq" },
            },
            {
              title: "Systemic History",
              links: { name: "admin.medics.systemic" },
            },
            {
              title: "Laboratory Data",
              links: { name: "admin.medics.lab" },
            },
          ],
        },
        {
          title: "Medicines",
          action: "mdi-circle-medium",
          items: [
            {
              title: "version",
              links: { name: "medi.mediverse" },
            },
            {
              title: "medicines",
              links: { name: "medi.medicines" },
            },
          ],
        },
      ],
    };
  },
  methods: {
    avatar() {
      let remote = localStorage.getItem("user.pix");
      // console.log("remote: " + remote);
      if (remote === "null" || remote === "") {
        return this.dataUrl;
      } else {
        return remote;
      }
    },
    openSide() {
      console.log(this.subdrawer);
      this.subdrawer = true;
    },
    closeSide() {
      this.subdrawer = false;
    },
    closePass(p) {
      this.passDialog = p.state;
    },
    submitPass(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .changePassword(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.passDialog = p.state;
    },
    closeProfile(p) {
      this.profileDialog = p.state;
    },
    submitProfile(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .update(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.profileDialog = p.state;
    },
    closePix(p) {
      this.pixDialog = p.state;
    },
    submitPix(p) {
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .updatePicture(p.data)
        .then((response) => {
          console.log(response.data);
          this.userPix = p.data;
          this.snack.color = "success";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.pixDialog = p.state;
    },
    logout() {
      localStorage.clear();
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>
